$phone: 600px;
$tablet: 1023px;
$dekstop: 1024px;

///global
.mt-4{
  margin-top: 16px;
}

.capitalize{
  text-transform: capitalize;
}

.main {
  margin-left: 230px;
  padding: 20px;
  border-radius: 20px;
  margin-right: 16px;
  height: calc(100vh - 104px);
  background-color: rgb(237, 235, 235);
  overflow-y: scroll;
}

.menu-item-setting.menu-item-setting {
  font-size: 12px;
  color: #999999;
  padding: 2px 10px;
}

.div-filter {
  display: flex;
  @media screen and (max-width: 1260px) {
    margin-left: -12px;
    margin-top: 12px;
  }
}

.outer-input {
  min-height: 100vh;
  background-color: rgb(236, 241, 229);
}

.label-input {
  width: 240px;
}

.div-input {
  display: flex;
}

.dropzone-div {
  width: 100%;
  border: 1px dashed #c9c7c7;
  border-radius: 4px;
  text-align: center;
  min-height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.dropzone-div-section {
  width: 100%;
  height: 100%;
}

.dropzone-div-section div {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.dropzone-div-section div input {
  cursor: pointer;
}

///login
.logo-login {
  width: 150px;
}

.root-login {
  height: 100vh;
}

.bg-login {
  width: 100%;
}

.box-left-login {
  padding: 5vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-right: 1px solid rgb(206, 200, 200);
  // background-image: url(../images/bg-3.jpg);
  // background-size: cover;
  @media screen and (max-width: $phone) {
    display: none;
  }
}
.title-head-login {
  font-weight: 800;
  margin: 10px 0px;
  text-align: center;
  font-size: 22px;
  // color:#f1f50b;
  // margin-bottom: px;
  @media screen and (max-width: $phone) {
    font-size: 18px;
  }
}

.head-login {
  font-weight: 600;
  margin: 10px 0px;
  text-align: center;
  font-size: 18px;
  // color:#f1f50b;
  margin-bottom: 12px;
  @media screen and (max-width: $phone) {
    font-size: 16px;
  }
}

.sub-head-login {
  font-size: 15px;
  text-align: center;
  @media screen and (max-width: $phone) {
    font-size: 13px;
  }
}

.box-form {
  padding: 40px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  height: calc(100% - 120px);
}

.btn-login {
  margin-top: 12px;
  margin-bottom: 30px;
  width: 100%;
}

///

.div-detail {
  display: flex;
  padding: 10px;
}

.MuiAutocomplete-hasPopupIcon.css-viou3o-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon.css-viou3o-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot {
  padding-right: 10px !important;
  font-size: 12px !important;
}

.color {
  color: #f4c5ad73;
}
